<template>
	<tablebox v-loading="loading" :element-loading-text="config.loading.text"
		:element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background"
		class="dp-f">
		<choose class="mr-20" :index="1" @Emit="butemit"
			:Data="[{ title: '全部' }, { title: '待我处理' }, { title: '待他人处理' }, { title: '签署完成' }, { title: '已过期' }, { title: '草稿箱' }]">
		</choose>
		<list style="overflow: auto;" :butIndex="state.butIndex" ref="listRef"></list>
	</tablebox>
</template>
<script setup>
import { reactive, ref, unref, nextTick } from 'vue'
import choose from "@/components/sidebar/choose.vue"
import list from "./list.vue"

const loading = ref(false);//loading 显示
const listRef = ref()
const state = reactive({
	butIndex: 1,//按钮选中下标
})
// 按钮返回
const butemit = ((el) => {
	state.butIndex = el
	nextTick(() => {
		unref(listRef).resetsearchKey()
		unref(listRef).getDataList()
	})
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>